import * as moment from 'moment';
import { MomentModule } from 'ngx-moment';

export class TrainingPeriod {
	public candidate_id;
	public start_date;
	public end_date;
	public id;
	public is_locked: any;
	public location;
	public location_id;
	public preferred_location_id;
	public ratio: any;
	public training_period_accumulation_type: any;
	public training_period_accumulation_type_id: any;
	public is_changed;
	public unavailability_periods: any;
	public training_change_part_id: any;
	public is_candidate_unavailability_period;
	public description;
	public is_sub_type;
	public preferred_order;
	public is_binding_location_preference;
	public sub_type_id;
	public new_start_date;
	public new_end_date;

	constructor(obj) {
		this.candidate_id = obj.candidate_id;
		this.start_date = obj.start_date;
		this.end_date = obj.unavailability_periods && obj.unavailability_periods.length > 0 ? obj.unavailability_periods[0].start_date : null;
		this.id = obj.id;
		this.is_locked = obj.locked;
		this.location = obj.location;
		this.location_id = obj.location_id;
		this.preferred_location_id = obj.preferred_location_id;
		this.ratio = obj.ratio;
		this.training_period_accumulation_type = obj.training_period_accumulation_type;
		this.training_period_accumulation_type_id = obj.training_period_accumulation_type_id;
		this.is_changed = obj.is_changed;
		this.unavailability_periods = obj.unavailability_periods;
		this.training_change_part_id = obj.training_change_part_id;
		this.is_candidate_unavailability_period = obj.is_candidate_unavailability_period;
		this.description = obj.description;
		this.is_sub_type = obj.is_sub_type;
		this.preferred_order = obj.preferred_order;
		this.is_binding_location_preference = obj.is_binding_location_preference;
		this.sub_type_id = obj.sub_type_id;
		this.new_start_date = obj.new_start_date;
		this.new_end_date = obj.new_end_date;
	}

	getSubTypeName() {
		var self = this;
		return this.sub_type_id ? this.subTypes.filter(function(z) { return z.id == self.sub_type_id; })[0].name : "";
  	}

	formatString() {
		var str = ""
		if (this.is_candidate_unavailability_period) {
			str += "Afwezigheid";
		} else {
			str += this.training_period_accumulation_type.name;
		}

		str += " (" + 
			moment(this.new_start_date).format("DD-MM-YYYY") + " t/m " +
			moment(this.new_end_date).subtract(1, 'day').format("DD-MM-YYYY") + 
		")";

		return str;
	}

	subTypes = [
		{ id: 1, name: "1. Acute neurologie" },
		{ id: 2, name: "2. Cerebrovasculaire aandoeningen" },
		{ id: 3, name: "3a. Intensive care & Medium care" },
		{ id: 4, name: "3b. Intensive care volwassenen" },
		{ id: 5, name: "4a. Bewegingsstoornissen AMC" },
		{ id: 6, name: "4b. Bewegingsstoornissen VUmc" },
		{ id: 7, name: "5. Neuropsychiatrie" },
		{ id: 8, name: "6. SOLK en somatisch-psychiatrische comorbiditeit" },
		{ id: 9, name: "7. Cognitieve neurologie" },
		{ id: 10, name: "8. Neurodegeneratieve ziekten: bewegingsstoornissen en cognitieve neurologie" },
		{ id: 11, name: "9. Multiple sclerose perifeer in combinatie met algemene polikliniek" },
		{ id: 12, name: "10. Multipele sclerose academisch" },
		{ id: 13, name: "11. Neuro-immunologische en neurologische infectieziekten" },
		{ id: 14, name: "12. Neuro-oncologie" },
		{ id: 15, name: "13. Neuromusculaire ziekten" },
		{ id: 16, name: "14. Klinische epileptologie" },
		{ id: 17, name: "15. Epilepsiechirurgie" },
		{ id: 18, name: "16. Ouderen, cognitieve stoornissen, bewegingsstoornissen en vallen" },
		{ id: 19, name: "17. Polikliniek algemene neurologie" },
		{ id: 20, name: "18. Rug- en radiculaire pijn" },
		{ id: 21, name: "19. Slaapgeneeskunde" },
		{ id: 22, name: "20. KNF perifeer" },
		{ id: 23, name: "21. KNF academisch" },
		{ id: 24, name: "22. KNF epilepsie en het EEG" },
		{ id: 25, name: "23. Leiderschap en management in combinatie met geïndividualiseerde polikliniek stage" },
		{ id: 26, name: "24. Leiderschap en opleiding in combinatie met geïndividualiseerde polikliniek stage" },
		{ id: 27, name: "25. Leiderschap en innovatie in combinatie met geïndividualiseerde polikliniek stage" },
		{ id: 28, name: "Onderwijs" },
		{ id: 29, name: "Overig" },
	];
} 
